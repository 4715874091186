import { Category } from "./../types";
import Vue from "vue";
import VueRouter from "vue-router";
import Login from "../components/Auth/Login.vue";

import DefaultTemplate from "@/components/shared/DefaultTemplate.vue";

import Dashboard from "../components/Dashboard.vue";

import TipList from "../components/Tip/TipList.vue";
import TipForm from "../components/Tip/TipForm.vue";

import StoryList from "../components/Story/StoryList.vue";
import StoryForm from "../components/Story/StoryForm.vue";

import UserList from "../components/User/UserList.vue";
import UserForm from "../components/User/UserForm.vue";

import CategoryList from "../components/Category/CategoryList.vue";
import CategoryForm from "../components/Category/CategoryForm.vue";

import ThemeList from "../components/Theme/ThemeList.vue";
import ThemeForm from "../components/Theme/ThemeForm.vue";

import CommentList from "../components/Comment/CommentList.vue";
import CommentForm from "../components/Comment/CommentForm.vue";

import ReportList from "../components/Report/ReportList.vue";
import CityCategoryForm from "@/components/City-Category/CityCategoryForm.vue";
import CityCategoryList from "@/components/City-Category/CityCategoryList.vue";

Vue.use(VueRouter);

const routes = [
  { path: "/auth", name: "auth", component: Login },
  {
    path: "/",
    name: "home",
    component: DefaultTemplate,
    redirect: "dashboard",
    children: [
      {
        path: "/dashboard",
        name: "dashboard",
        component: Dashboard
      },
      {
        path: "/tips",
        name: "tips",
        component: TipList,
        meta: {
          breadcrumb: [
            { label: "Dashboard", route: "dashboard" },
            { label: "Tip list" }
          ]
        }
      },
      {
        path: "/edit-tip/:tipId",
        name: "edit-tip",
        component: TipForm,
        meta: {
          breadcrumb: [
            { label: "Dashboard", route: "dashboard" },
            { label: "Tip list", route: "tips" },
            { label: "Edit tip" }
          ]
        }
      },
      {
        path: "/stories",
        name: "stories",
        component: StoryList,
        meta: {
          breadcrumb: [
            { label: "Dashboard", route: "dashboard" },
            { label: "Story list" }
          ]
        }
      },
      {
        path: "/edit-story/:storyId",
        name: "edit-story",
        component: StoryForm,
        meta: {
          breadcrumb: [
            { label: "Dashboard", route: "dashboard" },
            { label: "Story list", route: "stories" },
            { label: "Edit story" }
          ]
        }
      },
      {
        path: "/users",
        name: "users",
        component: UserList,
        meta: {
          breadcrumb: [
            { label: "Dashboard", route: "dashboard" },
            { label: "User list" }
          ]
        }
      },
      {
        path: "/edit-user/:userId",
        name: "edit-user",
        component: UserForm,
        meta: {
          breadcrumb: [
            { label: "Dashboard", route: "dashboard" },
            { label: "User list", route: "users" },
            { label: "Edit user" }
          ]
        }
      },
      {
        path: "/categories",
        name: "categories",
        component: CategoryList,
        meta: {
          breadcrumb: [
            { label: "Dashboard", route: "dashboard" },
            { label: "Category list" }
          ]
        }
      },
      {
        path: "/edit-category/:categoryId",
        name: "edit-category",
        component: CategoryForm,
        meta: {
          breadcrumb: [
            { label: "Dashboard", route: "dashboard" },
            { label: "Category list", route: "categories" },
            { label: "Edit category" }
          ]
        }
      },
      {
        path: "/create-category",
        name: "create-category",
        component: CategoryForm,
        meta: {
          breadcrumb: [
            { label: "Dashboard", route: "dashboard" },
            { label: "Category list", route: "categories" },
            { label: "Create category" }
          ]
        }
      },
      {
        path: "/articles",
        name: "articles",
        component: CityCategoryList,
        meta: {
          breadcrumb: [
            { label: "Dashboard", route: "dashboard" },
            { label: "Articles list" }
          ]
        }
      },
      {
        path: "/edit-city-category/:cityId-:categoryId",
        name: "edit-city-category",
        component: CityCategoryForm,
        meta: {
          breadcrumb: [
            { label: "Dashboard", route: "dashboard" },
            { label: "Category list", route: "articles" },
            { label: "Edit Article" }
          ]
        }
      },
      {
        path: "/themes",
        name: "themes",
        component: ThemeList,
        meta: {
          breadcrumb: [
            { label: "Dashboard", route: "dashboard" },
            { label: "Theme list" }
          ]
        }
      },
      {
        path: "/edit-theme/:themeId",
        name: "edit-theme",
        component: ThemeForm,
        meta: {
          breadcrumb: [
            { label: "Dashboard", route: "dashboard" },
            { label: "Theme list", route: "themes" },
            { label: "Edit theme" }
          ]
        }
      },
      {
        path: "/create-theme",
        name: "create-theme",
        component: ThemeForm,
        meta: {
          breadcrumb: [
            { label: "Dashboard", route: "dashboard" },
            { label: "Theme list", route: "themes" },
            { label: "Create theme" }
          ]
        }
      },
      {
        path: "/comments",
        name: "comments",
        component: CommentList,
        meta: {
          breadcrumb: [
            { label: "Dashboard", route: "dashboard" },
            { label: "Comment list" }
          ]
        }
      },
      {
        path: "/edit-comment/:commentId",
        name: "edit-comment",
        component: CommentForm,
        meta: {
          breadcrumb: [
            { label: "Dashboard", route: "dashboard" },
            { label: "Comment list", route: "comments" },
            { label: "Edit comment" }
          ]
        }
      },
      {
        path: "/reports",
        name: "reports",
        component: ReportList,
        meta: {
          breadcrumb: [
            { label: "Dashboard", route: "dashboard" },
            { label: "Report list" }
          ]
        }
      }
    ]
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
