var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-card',[_c('v-card-title',[_c('v-spacer'),_c('v-text-field',{attrs:{"append-icon":"mdi-search","label":"Search URL","placeholder":"/fr/Restaurants-999-City_Country","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"item-key":"id","headers":_vm.headers,"items":_vm.filteredCitiesCategories(),"loading":_vm.isLoading,"sort-by":"absoluteOrder"},scopedSlots:_vm._u([{key:"item.createdAt",fn:function({ item }){return [_vm._v(_vm._s(_vm._f("dateFormat")(new Date(item.createdAt),"DD/MM/YYYY HH:mm:ss")))]}},{key:"item.city.city",fn:function({ item }){return [_vm._v(" "+_vm._s(item.city.city)+" #"+_vm._s(item.city.id)+" ")]}},{key:"item.url",fn:function({ item }){return [_c('a',{attrs:{"href":item.full_url,"target":"_blank"}},[_vm._v(_vm._s(item.simple_url))])]}},{key:"item.content",fn:function({ item }){return [_c('span',{domProps:{"innerHTML":_vm._s(item.content)}})]}},{key:"item.category",fn:function({ item }){return [(item.category && item.category.id !== 'all')?_c('router-link',{attrs:{"to":{
          name: 'edit-category',
          params: { categoryId: item.category.id }
        }}},[_vm._v(_vm._s(item.category.title))]):(item.category && item.category.id === 'all')?_c('span',[_vm._v(_vm._s(item.category.title))]):_c('span',[_vm._v("_")])]}},{key:"item.action",fn:function({ item }){return [_c('router-link',{attrs:{"to":{
          name: 'edit-city-category',
          params: { cityId: item.city.id, categoryId: item.category.id }
        }}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":""}},[_vm._v("mdi-pen")])],1)]}}]),model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }