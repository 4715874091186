
import { Component, Vue, Prop } from "vue-property-decorator";

import axios from "axios";
import { Story } from "@/types";

import Loading from "../shared/Loading.vue";
import DisplayStories from "./DisplayStories.vue";

import { QUERY_GET_STORIES } from "./graphql";

@Component({ components: { DisplayStories, Loading } })
export default class StoryList extends Vue {
  loading: boolean = false;

  stories: Story[] = [];

  error: boolean = false;

  mounted() {
    this.fetchStories();
  }

  async fetchStories() {
    this.loading = true;

    try {
      const { data } = await this.$apollo.query({ query: QUERY_GET_STORIES });

      if (!data) {
        throw new Error("Unable to find stories");
      }

      this.stories = data.allStories;
      this.loading = false;
    } catch (err) {
      this.$store.commit("setSnackMessageWithColor", {
        message: err,
        color: "error"
      });
    }
  }

  handleBackClick() {
    this.$router.push({ name: "dashboard" });
  }

  async handleRefreshData() {
    await this.fetchStories();
    this.$store.commit("setSnackMessageWithColor", {
      message: "Data has been refreshed",
      color: "success"
    });
  }

  handleOnCreateNewStory() {
    this.$router.push({ name: "create-story" });
  }
}
