export enum ModerationEnum {
  PENDING = "pending",
  VALID = "valid",
  BLOQUED = "bloqued",
  DELETED = "deleted"
}

export class User {
  id?: string;
  email?: string;
  birthday?: Date;
  isActive?: boolean;
  isAdmin?: boolean;
  isVerified?: boolean;
  fullname?: string;
  username?: string;
  moderation?: ModerationEnum;
  profileImage?: Media;
  createdAt?: Date;
  updatedAt?: Date;
  topComments?: Comment[];
  topTips?: Tip[];
}

export class Category {
  id?: string;
  title?: string;
  title_en?: string;
  description?: string;
  order: number = 0;
  absoluteOrder?: number;
  icon?: string;
  color?: string;
  isLocation?: boolean;
  createdAt?: Date;
  updatedAt?: Date;
  parentCategory?: Category;
}

export class City {
  id?: number;
  city?: string;
  administrativeArea1?: string | null;
  administrativeArea2?: string | null;
  country?: string | null;
}

export class CityCategory {
  id?: string;
  city?: City;
  category?: Category;
  title?: string;
  content?: string;
  simple_url?: string;
  full_url?: string;
}

export class Tip {
  id?: string;
  title?: string;
  description?: string;
  moderation?: ModerationEnum;
  createdAt?: Date;
  updatedAt?: Date;
  author?: User;
  category?: Category;
  Comments?: Comment[];
}

export class Media {
  id?: string;
  type?: string;
  image?: Image;
  video?: Video;
  createdAt?: Date;
  updatedAt?: Date;
}

export class Image {
  originalFile?: string;
  thumbnailFile?: string;
  bigFile?: string;
}

export class Video {
  m3u8File?: string;
}

export class Comment {
  id?: string;
  text?: string;
  moderation?: ModerationEnum;
  createdAt?: Date;
  updatedAt?: Date;
  author?: User;
  Tip?: Tip;
}

export class Theme {
  id?: string;
  title?: string;
  order: number = 0;
  color?: string;
  createdAt?: Date;
  updatedAt?: Date;
}

export class Story {
  id?: string;
  title?: string;
  moderation?: ModerationEnum;
  createdAt?: Date;
  updatedAt?: Date;
  author?: User;
  theme?: Theme;
  comments?: Comment[];
}

export class Report {
  id?: string;
  createdAt?: Date;
  tipId?: string;
  storyId?: string;
  userId?: string;
  commentId?: string;
  reporter?: User;
  reporterId?: string;
}
