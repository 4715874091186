import gql from "graphql-tag";

export const QUERY_GET_THEMES = gql`
  query get_themes {
    themes {
      id
      title
      order
      color
      createdAt
    }
  }
`;

export const QUERY_GET_THEME_BY_ID = gql`
  query get_theme($themeId: String!) {
    theme(id: $themeId) {
      id
      title
      order
      color
      createdAt
    }
  }
`;

export const MUTATION_ADD_THEME = gql`
  mutation CreateTheme($theme: NewThemeInput!) {
    addTheme(newThemeData: $theme) {
      id
    }
  }
`;

export const MUTATION_EDIT_THEME = gql`
  mutation UpdateTheme($theme: UpdateThemeInput!) {
    updateTheme(updateThemeData: $theme) {
      id
    }
  }
`;
