
import { Component, Vue } from "vue-property-decorator";

import { CityCategory } from "@/types";

import Loading from "../shared/Loading.vue";

import { QUERY_GET_FULL_CITY_CATEGORIES } from "./graphql";
import DisplayCitiesCategories from "./DisplayCitiesCategories.vue";
import { getCityLink } from "./linksHelper";

@Component({ components: { DisplayCitiesCategories, Loading } })
export default class CityCategoryList extends Vue {
  loading: boolean = false;

  cities_categories: CityCategory[] = [];

  error: boolean = false;

  mounted() {
    this.fetchCitiesCategories();
  }

  async fetchCitiesCategories() {
    this.loading = true;

    try {
      const { data } = await this.$apollo.query({
        query: QUERY_GET_FULL_CITY_CATEGORIES,
        variables: {
          language: "fr"
        }
      });

      if (!data) {
        throw new Error("Unable to find articles");
      }

      this.cities_categories = data.fullCitiesCategories.map(
        (city_category: CityCategory) => {
          return { ...city_category, ...getCityLink(city_category) };
        }
      );

      // console.log("cities_categories", JSON.stringify(this.cities_categories));

      this.loading = false;
    } catch (err) {
      this.$store.commit("setSnackMessageWithColor", {
        message: err,
        color: "error"
      });
    }
  }

  handleBackClick() {
    this.$router.push({ name: "dashboard" });
  }

  async handleRefreshData() {
    await this.fetchCitiesCategories();
    this.$store.commit("setSnackMessageWithColor", {
      message: "Data has been refreshed",
      color: "success"
    });
  }

  // handleOnCreateNewCategory() {
  //   this.$router.push({ name: "create-category" });
  // }
}
