
import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import Loading from "@/components/shared/Loading.vue";
import EventBus from "@/components/shared/EventBus.vue";
import ConfirmDialog from "@/components/shared/ConfirmDialog.vue";

import {
  QUERY_GET_THEME_BY_ID,
  MUTATION_ADD_THEME,
  MUTATION_EDIT_THEME
} from "./graphql";

import axios from "axios";
import { Theme } from "@/types";

@Component({ components: { Loading } })
export default class ThemeForm extends Vue {
  theme: Theme | null = null;

  formRules: any = {
    required: [(v: number) => !!v || "Field is required"],
    requiredNumber: [(v: number) => v >= 0 || "Must be greater than 0"]
  };

  formIsValid: boolean = false;

  formTitle: string = "";
  formColor: string = "";
  formOrder: number = 0;

  loading: boolean = false;

  confirmDialogIsOpen: boolean = false;

  previewImageUrl: string | null = null;

  vueAppAdminUrl: string = "";

  get themeId(): string {
    const { themeId = "" } = this.$route.params;
    return themeId;
  }

  get isEditMode(): boolean {
    return this.themeId !== "";
  }

  get form(): any {
    return this.$refs.form;
  }

  async mounted() {
    this.loading = true;

    await this.fetchTheme();

    this.loading = false;
  }

  async fetchTheme() {
    if (!this.isEditMode) return;

    try {
      const { data } = await this.$apollo.query({
        query: QUERY_GET_THEME_BY_ID,
        variables: { themeId: this.themeId }
      });

      if (!data && data.theme !== null) {
        throw new Error("Unable to find theme");
      }
      this.theme = data.theme;
      this.formTitle = data.theme.title || "";
      this.formColor = data.theme.color || "";
      this.formOrder = data.theme.order || 0;

      EventBus.$emit("handleAddToBreadcrumb", [{ label: this.formTitle }]);
    } catch (err) {
      this.$store.commit("setSnackMessageWithColor", {
        message: err,
        color: "error"
      });
      this.$router.push({ name: "themes" });
    }
  }

  handleBackClick() {
    this.$router.push({ name: "themes" });
  }

  async handleOnUpdate(close: boolean) {
    if (!this.form.validate()) return;
    this.loading = true;

    try {
      const variables = {
        theme: {
          id: this.isEditMode ? this.themeId : undefined,
          title: this.formTitle,
          color: this.formColor,
          order: +this.formOrder
        }
      };

      const mutation =
        this.theme && this.themeId ? MUTATION_EDIT_THEME : MUTATION_ADD_THEME;
      const { data } = await this.$apollo.mutate({ mutation, variables });

      this.$store.commit("setSnackMessageWithColor", {
        message: "Theme informations was saved successfully",
        color: "success"
      });

      if (close) {
        this.$router.push({ name: "themes" });
        return;
      }
    } catch (err) {
      this.$store.commit("setSnackMessageWithColor", {
        message: err,
        color: "error"
      });
    }

    this.loading = false;
  }
}
