import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import apolloClient from "./core/ApolloClient";
import "./assets/styles.scss";
import wysiwyg from "vue-wysiwyg-fixed";
import VueApollo from "vue-apollo";
import vuetify from "./plugins/vuetify";
import VuetifyConfirm from "vuetify-confirm";
import * as dotenv from "dotenv";
// node_modules\vue-wysiwyg\dist\vueWysiwyg.css
import "vue-wysiwyg-fixed/dist/vueWysiwyg.css";

dotenv.config();

Vue.config.productionTip = false;

Vue.use(VueApollo);

const apolloProvider = new VueApollo({
  defaultClient: apolloClient
});

Vue.use(VuetifyConfirm, { vuetify });

// https://github.com/chmln/vue-wysiwyg
Vue.use(wysiwyg, {
  hideModules: {
    image: true,
    table: true,
    code: true,
    orderedList: true,
    unorderedList: true,
    removeFormat: true,
    justifyLeft: true,
    justifyCenter: true,
    justifyRight: true,
    headings: true,
    link: true,
    separator: true
  },

  forcePlainTextOnPaste: true
});

new Vue({
  router,
  store,
  apolloProvider,
  vuetify,
  render: h => h(App)
}).$mount("#app");
