import gql from "graphql-tag";

export const MUTATION_LOGIN = gql`
  mutation adminLogin($login: String!, $password: String!) {
    adminLogin(login: $login, password: $password)
  }
`;

export const QUERY_GET_ME = gql`
  query me {
    whoAmI {
      id
      firstname
      lastname
      birthday
      gender
      isActive
      isAdmin
      createdAt
      updatedAt
      profileImage {
        id
        position
        createdAt
        image {
          originalFile
          thumbnailFile
        }
      }
      topTips {
        id
      }
    }
  }
`;
