
import { Component, Vue, Prop } from "vue-property-decorator";

import axios from "axios";
import { Tip } from "@/types";

import Loading from "../shared/Loading.vue";
import DisplayTips from "./DisplayTips.vue";

import { QUERY_GET_TIPS } from "./graphql";

@Component({ components: { DisplayTips, Loading } })
export default class TipList extends Vue {
  loading: boolean = false;

  tips: Tip[] = [];

  error: boolean = false;

  mounted() {
    this.fetchTips();
  }

  async fetchTips() {
    this.loading = true;

    try {
      const { data } = await this.$apollo.query({ query: QUERY_GET_TIPS });

      if (!data) {
        throw new Error("Unable to find tips");
      }

      this.tips = data.tips;
      this.loading = false;
    } catch (err) {
      this.$store.commit("setSnackMessageWithColor", {
        message: err,
        color: "error"
      });
    }
  }

  handleBackClick() {
    this.$router.push({ name: "dashboard" });
  }

  async handleRefreshData() {
    await this.fetchTips();
    this.$store.commit("setSnackMessageWithColor", {
      message: "Data has been refreshed",
      color: "success"
    });
  }

  handleOnCreateNewTip() {
    this.$router.push({ name: "create-tip" });
  }
}
