
import { Component, Vue, Prop } from "vue-property-decorator";

import axios from "axios";
import { Category } from "@/types";

import Loading from "../shared/Loading.vue";
import DisplayCategories from "./DisplayCategories.vue";

import { QUERY_GET_CATEGORIES } from "./graphql";

@Component({ components: { DisplayCategories, Loading } })
export default class CategoryList extends Vue {
  loading: boolean = false;

  categories: Category[] = [];

  error: boolean = false;

  mounted() {
    this.fetchCategories();
  }

  async fetchCategories() {
    this.loading = true;

    try {
      const { data } = await this.$apollo.query({
        query: QUERY_GET_CATEGORIES
      });

      if (!data) {
        throw new Error("Unable to find categories");
      }

      this.categories = data.categories.map((category: Category) => {
        if (category.parentCategory) {
          category.absoluteOrder =
            1 + category.order + category.parentCategory.order * 1000;
        } else {
          category.absoluteOrder = category.order * 1000;
        }
        return category;
      });
      this.loading = false;
    } catch (err) {
      this.$store.commit("setSnackMessageWithColor", {
        message: err,
        color: "error"
      });
    }
  }

  handleBackClick() {
    this.$router.push({ name: "dashboard" });
  }

  async handleRefreshData() {
    await this.fetchCategories();
    this.$store.commit("setSnackMessageWithColor", {
      message: "Data has been refreshed",
      color: "success"
    });
  }

  handleOnCreateNewCategory() {
    this.$router.push({ name: "create-category" });
  }
}
