import gql from "graphql-tag";

export const QUERY_GET_COMMENTS = gql`
  query get_comments {
    comments(limit: 1000) {
      id
      text
      createdAt
      moderation
      author {
        id
        email
        createdAt
      }
      tip {
        id
        title
        createdAt
      }
    }
  }
`;

export const QUERY_GET_COMMENT_BY_ID = gql`
  query get_comment($commentId: String!) {
    comment(id: $commentId) {
      id
      text
      createdAt
      moderation
      author {
        id
        email
        createdAt
      }
      tip {
        id
        title
        createdAt
      }
    }
  }
`;

export const MUTATION_EDIT_COMMENT = gql`
  mutation UpdateComment($comment: UpdateCommentInput!) {
    updateComment(updateCommentData: $comment) {
      id
    }
  }
`;
