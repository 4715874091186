
import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import { Category } from "@/types";

@Component
export default class Displaycategories extends Vue {
  @Prop() categories!: Category[];

  @Prop() loading!: boolean;

  isLoading: boolean = false;

  selected: any = [];

  pagination: any = {
    sortBy: "order"
  };

  headers: any = [
    { text: "Titre", value: "title" },
    { text: "Ordre", value: "order" },
    { text: "Catégorie parente", value: "parentCategory" },
    { text: "Date de création", value: "createdAt" },
    { text: "Actions", value: "action", sortable: false }
  ];

  search: String = "";

  @Watch("loading", { immediate: true })
  handleLoadingChange(value: boolean) {
    this.isLoading = value;
  }

  toggleAll() {
    if (this.selected.length) this.selected = [];
    else this.selected = this.categories.slice();
  }

  changeSort(column: string) {
    if (this.pagination.sortBy === column) {
      this.pagination.descending = !this.pagination.descending;
    } else {
      this.pagination.sortBy = column;
      this.pagination.descending = false;
    }
  }
}
