import gql from "graphql-tag";

export const QUERY_GET_TOP_CATEGORIES = gql`
  query get_top_categories {
    topLevelCategories {
      id
      title
      order
      color
      icon
      isLocation
      createdAt
    }
  }
`;

export const QUERY_GET_CATEGORIES = gql`
  query get_categories {
    categories {
      id
      title
      description
      order
      color
      icon
      isLocation
      createdAt
      parentCategory {
        id
        title
        order
      }
    }
  }
`;

export const QUERY_GET_CATEGORY_BY_ID = gql`
  query get_category($categoryId: String!) {
    category(id: $categoryId) {
      id
      title
      description
      order
      color
      icon
      isLocation
      createdAt
      parentCategory {
        id
        title
        order
      }
    }
  }
`;

export const MUTATION_ADD_CATEGORY = gql`
  mutation CreateCategory($category: NewCategoryInput!) {
    addCategory(newCategoryData: $category) {
      id
    }
  }
`;

export const MUTATION_EDIT_CATEGORY = gql`
  mutation UpdateCategory($category: UpdateCategoryInput!) {
    updateCategory(updateCategoryData: $category) {
      id
    }
  }
`;
