import { City, CityCategory } from "@/types";

export const plural = (input: string) => {
  const splits = input.split(/\s+/);
  // eslint-disable-next-line i18next/no-literal-string
  splits[0] += "s";
  return splits.join(" ");
};

export const safeURL = (input: string) => {
  return encodeURIComponent(
    input
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "")
      .replace(/[\s*,-]+/g, "_")
  );
};

export const getCityURLText = (city: City) => {
  const res: string[] = [];
  if (city.city) {
    res.push(safeURL(city.city));
    if (city.country) res.push(safeURL(city.country));
    if (!res.length && city.administrativeArea2)
      res.push(safeURL(city.administrativeArea2));
    if (!res.length && city.administrativeArea1)
      res.push(safeURL(city.administrativeArea1));
  } else {
    if (city.administrativeArea2) res.push(safeURL(city.administrativeArea2));
    if (!res.length && city.administrativeArea1)
      res.push(safeURL(city.administrativeArea1));
    if (city.country) res.push(safeURL(city.country));
  }

  return res.join("_");
};

export const ALL_CITIES = "anywhere";
export const ALL_CATEGORY = "Thing To Do";
// export const ALL_CATEGORY_url = "Things_To_Do";
// export const CATEGORY_mapping: { [key: string]: string } = {
//   "Thing to Do": "Activitie",
//   "": ALL_CATEGORY_title
// };

export interface CityCategoryLink {
  simple_url: string;
  full_url: string;
}

const ___get_category_title = (item: CityCategory): string => {
  if (!item.category) return ALL_CATEGORY;
  const title = item.category.title_en || "";
  // if (title in CATEGORY_mapping) {
  //   return CATEGORY_mapping[title];
  // }
  return title;
};

export const getCityLink = (item: CityCategory): CityCategoryLink => {
  const locale = "fr";

  const category_title = ___get_category_title(item);
  const url_category = safeURL(plural(category_title));

  // console.log(`${JSON.stringify(item.category)} -> ${url_category}`);

  const url_city = item.city
    ? `${item.city.id}-${safeURL(getCityURLText(item.city))}`
    : `${ALL_CITIES}-${ALL_CITIES}`;

  const simple_url = `/${locale}/${url_category}-${url_city}`;
  return {
    simple_url,
    full_url: process.env.VUE_APP_WWW_URL + simple_url
  };
};
