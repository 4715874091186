import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    user: null,
    snackMessage: null,
    snackColor: "primary",
    snackKey: Math.random()
  },
  mutations: {
    setUser: (state, user) => {
      Object.assign(state, { user });
    },
    setSnackMessage: (state, newState) => {
      Object.assign(state, { snackMessage: newState, snackKey: Math.random() });
    },
    setSnackMessageWithColor: (state, newState) => {
      const { message, color = "primary" } = newState;
      Object.assign(state, {
        snackMessage: message,
        snackColor: color,
        snackKey: Math.random()
      });
    }
  },
  actions: {},
  modules: {},
  getters: {
    getUser(state) {
      return state.user;
    },
    getSnackMessage(state) {
      return state.snackMessage;
    },
    getSnackMessageWithColor(state) {
      return {
        message: state.snackMessage,
        color: state.snackColor,
        snackKey: state.snackKey
      };
    }
  }
});
