import gql from "graphql-tag";

export const QUERY_GET_USERS = gql`
  query get_users {
    users(searchUserData: { meIncluded: true }, limit: 100000) {
      id
      email
      fullname
      username
      moderation
      isActive
      isAdmin
      isVerified
      createdAt
    }
  }
`;

export const QUERY_COUNT_USER = gql`
  query count_user {
    nbUser
  }
`;

export const QUERY_GET_USER_BY_ID = gql`
  query get_user($userId: String!) {
    user(id: $userId) {
      id
      email
      moderation
      birthday
      fullname
      username
      isActive
      isAdmin
      isVerified
      createdAt
      profileImage {
        id
        image {
          thumbnailFile
          bigFile
        }
      }
      topComments {
        id
        text
        createdAt
      }
      topTips {
        id
        title
        description
        createdAt
      }
    }
  }
`;

export const MUTATION_EDIT_USER = gql`
  mutation UpdateUser($user: UpdateUserInput!) {
    updateUser(updateUserData: $user) {
      id
    }
  }
`;
