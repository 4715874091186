import gql from "graphql-tag";

export const QUERY_GET_FULL_CITY_CATEGORIES = gql`
  query GetFullCitiesCategories($language: String!) {
    fullCitiesCategories(language: $language) {
      id
      city {
        id
        city
        administrativeArea1
        administrativeArea2
        country
      }
      category {
        id
        title
        title_en
      }
      title
      content
    }
  }
`;

export const QUERY_GET_FULL_CITY_CATEGORY_BY_ID = gql`
  query GetFullCityCategoryByIds(
    $cityId: Int!
    $categoryId: String!
    $language: String!
  ) {
    fullCityCategory(
      cityId: $cityId
      categoryId: $categoryId
      language: $language
    ) {
      id
      city {
        id
        city
      }
      category {
        id
        title
      }
      title
      content
    }
  }
`;

// export const QUERY_GET_CATEGORY_BY_ID = gql`
//   query get_category($categoryId: String!) {
//     category(id: $categoryId) {
//       id
//       title
//       description
//       order
//       color
//       icon
//       isLocation
//       createdAt
//       parentCategory {
//         id
//         title
//         order
//       }
//     }
//   }
// `;

// export const MUTATION_ADD_CATEGORY = gql`
//   mutation CreateCategory($category: NewCategoryInput!) {
//     addCategory(newCategoryData: $category) {
//       id
//     }
//   }
// `;

export const MUTATION_EDIT_CITY_CATEGORY = gql`
  mutation UpdateCityCategory($city_category: UpdateCityCategoryInput!) {
    updateCityCategory(updateCityCategoryData: $city_category) {
      cityId
      categoryId
      language
    }
  }
`;
