
import { Component, Vue, Prop } from "vue-property-decorator";
import axios from "axios";
import { QUERY_GET_ME } from "./graphql";

@Component
export default class Login extends Vue {
  loading: boolean = false;

  formIsValid: boolean = false;

  formLogin: string = "";

  formPassword: string = "";

  formRules: any = {
    required: [(v: number) => !!v || "Field is required"]
  };

  async mounted() {
    if (localStorage.getItem("user_token")) {
      try {
        await this.getMe();
      } catch (err) {
        localStorage.removeItem("user_token");

        this.$store.commit("setSnackMessageWithColor", {
          message: "Votre session a expiré, veuillez vous reconnecter",
          color: "error"
        });

        this.loading = false;
      }
    }
  }

  get form(): any {
    return this.$refs.form;
  }

  handleOnSubmit() {
    if (!this.form.validate()) return;
    this.login();
  }

  async login() {
    this.loading = true;

    try {
      const variables = {
        email: this.formLogin,
        password: this.formPassword
      };

      const { data } = await axios.post(
        process.env.VUE_APP_ADMIN_URL + "/api/auth/login",
        variables,
        {
          headers: {
            "Content-Type": "application/json",
            withCredentials: false,
            "Access-Control-Allow-Origin": "*"
          }
        }
      );
      if (!data || !data.accessToken) throw new Error("Wrong credentials");

      localStorage.setItem("user_token", data.accessToken);

      await this.getMe();

      this.$store.commit("setSnackMessageWithColor", {
        message: "Login success",
        color: "success"
      });
    } catch (err) {
      this.$store.commit("setSnackMessageWithColor", {
        message: err,
        color: "error"
      });

      localStorage.removeItem("user_token");
      this.loading = false;
    }
  }

  async getMe() {
    this.loading = true;

    const { data } = await this.$apollo.query({
      query: QUERY_GET_ME
    });

    if (!data || !data.whoAmI) throw new Error("Wrong credentials");

    if (!data.whoAmI.isAdmin) throw new Error("User is not admin");

    this.$store.commit("setUser", data.whoAmI);

    if (this.$route.query.redirect) {
      window.location.href = this.$route.query.redirect.toString();
      return;
    }

    this.$router.push({ name: "dashboard" });
  }
}
