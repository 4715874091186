
import { Component, Vue, Prop } from "vue-property-decorator";

import axios from "axios";
import { Comment } from "@/types";

import Loading from "../shared/Loading.vue";
import DisplayComments from "./DisplayComments.vue";

import { QUERY_GET_COMMENTS } from "./graphql";

@Component({ components: { DisplayComments, Loading } })
export default class CommentList extends Vue {
  loading: boolean = false;

  comments: Comment[] = [];

  error: boolean = false;

  mounted() {
    this.fetchComments();
  }

  async fetchComments() {
    this.loading = true;

    try {
      const { data } = await this.$apollo.query({ query: QUERY_GET_COMMENTS });

      if (!data) {
        throw new Error("Unable to find comments");
      }

      this.comments = data.comments;
      this.loading = false;
    } catch (err) {
      this.$store.commit("setSnackMessageWithColor", {
        message: err,
        color: "error"
      });
    }
  }

  handleBackClick() {
    this.$router.push({ name: "dashboard" });
  }

  async handleRefreshData() {
    await this.fetchComments();
    this.$store.commit("setSnackMessageWithColor", {
      message: "Data has been refreshed",
      color: "success"
    });
  }

  handleOnCreateNewComment() {
    this.$router.push({ name: "create-comment" });
  }
}
