
import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import Loading from "@/components/shared/Loading.vue";
import EventBus from "@/components/shared/EventBus.vue";
import ConfirmDialog from "@/components/shared/ConfirmDialog.vue";

import { QUERY_GET_STORY_BY_ID, MUTATION_EDIT_STORY } from "./graphql";

import axios from "axios";
import { Story, ModerationEnum } from "@/types";

@Component({ components: { Loading, ConfirmDialog } })
export default class StoryForm extends Vue {
  story: Story | null = null;
  formRules: any = {
    required: [(v: number) => !!v || "Field is required"],
    requiredNumber: [(v: number) => v >= 0 || "Must be greater than 0"]
  };

  formIsValid: boolean = false;

  formTitle: string = "";

  loading: boolean = false;

  confirmDialogIsOpen: boolean = false;

  previewImageUrl: string | null = null;

  vueAppAdminUrl: string = "";

  statusDict = { pending: "en cours", refused: "refusé", processed: "traité" };

  get storyId(): string {
    const { storyId = "" } = this.$route.params;
    return storyId;
  }

  get isEditMode(): boolean {
    return this.storyId !== "";
  }

  get form(): any {
    return this.$refs.form;
  }

  async mounted() {
    this.loading = true;

    this.vueAppAdminUrl = process.env.VUE_APP_ADMIN_URL + "";

    await this.fetchStory();

    this.loading = false;
  }

  async fetchStory() {
    if (!this.isEditMode) return;

    try {
      const { data } = await this.$apollo.query({
        query: QUERY_GET_STORY_BY_ID,
        variables: { storyId: this.storyId }
      });

      if (!data && data.story !== null) {
        throw new Error("Unable to find story");
      }
      this.story = data.story;
      this.formTitle = data.story.id || "";

      EventBus.$emit("handleAddToBreadcrumb", [{ label: this.formTitle }]);
    } catch (err) {
      this.$store.commit("setSnackMessageWithColor", {
        message: err,
        color: "error"
      });
      this.$router.push({ name: "stories" });
    }
  }

  handleBackClick() {
    this.$router.push({ name: "stories" });
  }

  async handleOnUpdate(close: boolean) {
    if (!this.form.validate()) return;
    this.loading = true;

    try {
      this.$store.commit("setSnackMessageWithColor", {
        message: "Story informations was saved successfully",
        color: "success"
      });

      if (close) {
        this.$router.push({ name: "stories" });
        return;
      }
    } catch (err) {
      this.$store.commit("setSnackMessageWithColor", {
        message: err,
        color: "error"
      });
    }

    this.loading = false;
  }

  isDeleted() {
    return this.story
      ? this.story.moderation === ModerationEnum.DELETED
      : false;
  }

  isBlocked() {
    return this.story
      ? this.story.moderation === ModerationEnum.BLOQUED
      : false;
  }

  isPending() {
    return this.story
      ? this.story.moderation === ModerationEnum.PENDING
      : false;
  }

  isValid() {
    return this.story ? this.story.moderation === ModerationEnum.VALID : false;
  }

  async handleOnDelete() {
    this.loading = true;

    const res = await this.$confirm(
      "Voulez-vous rééelement supprimé l'expérience ?",
      { title: "Warning" }
    );

    if (res) {
      try {
        const { data } = await this.$apollo.mutate({
          mutation: MUTATION_EDIT_STORY,
          variables: {
            story: { id: this.storyId, moderation: ModerationEnum.DELETED }
          }
        });

        this.$store.commit("setSnackMessageWithColor", {
          message: "Story was deleted",
          color: "success"
        });

        this.$router.push({ name: "stories" });
      } catch (err) {
        this.$store.commit("setSnackMessageWithColor", {
          message: err,
          color: "error"
        });
      }
    }

    this.loading = false;
  }

  async handleOnBlock() {
    this.loading = true;
    try {
      const { data } = await this.$apollo.mutate({
        mutation: MUTATION_EDIT_STORY,
        variables: {
          story: { id: this.storyId, moderation: ModerationEnum.BLOQUED }
        }
      });

      this.$store.commit("setSnackMessageWithColor", {
        message: "Story was bloqued",
        color: "success"
      });

      this.fetchStory();
    } catch (err) {
      this.$store.commit("setSnackMessageWithColor", {
        message: err,
        color: "error"
      });
    }

    this.loading = false;
  }

  async handleOnValid() {
    this.loading = true;

    try {
      const { data } = await this.$apollo.mutate({
        mutation: MUTATION_EDIT_STORY,
        variables: {
          story: { id: this.storyId, moderation: ModerationEnum.VALID }
        }
      });

      this.$store.commit("setSnackMessageWithColor", {
        message: "Story was validated",
        color: "success"
      });

      this.fetchStory();
    } catch (err) {
      this.$store.commit("setSnackMessageWithColor", {
        message: err,
        color: "error"
      });
    }

    this.loading = false;
  }
}
