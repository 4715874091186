
import { Component, Vue, Prop } from "vue-property-decorator";

import EventBus from "@/components/shared/EventBus.vue";

@Component
export default class LeftMenu extends Vue {
  isOpen: boolean = true;

  groups: any = [
    {
      label: "Dashboard",
      items: [
        {
          label: "Dashboard",
          icon: "mdi-view-dashboard",
          routeName: "dashboard"
        }
      ]
    },
    {
      label: "Tip",
      items: [
        { label: "Tip", icon: "mdi-account-voice", routeName: "tips" },
        // eslint-disable-next-line prettier/prettier
        { label: "Categories", icon: "mdi-room-service", routeName: "categories" },
        // eslint-disable-next-line prettier/prettier
        { label: "Articles", icon: "mdi-airplane", routeName: "articles" },
        { label: "Comment", icon: "mdi-comment", routeName: "comments" }
      ]
    },
    {
      label: "Story",
      items: [
        { label: "Story", icon: "mdi-account-voice", routeName: "stories" },
        {
          label: "Themes",
          icon: "mdi-room-service",
          routeName: "themes"
        }
      ]
    },
    {
      label: "User",
      items: [
        { label: "User", icon: "mdi-account", routeName: "users" },
        // eslint-disable-next-line prettier/prettier
        { label: "Reports", icon: "mdi-alert-circle", routeName: "reports" },
      ]
    }
  ];

  mounted() {
    EventBus.$on("handleToggleDrawer", this.handleToggleDrawer);
  }

  destroyed() {
    EventBus.$off("handleToggleDrawer", this.handleToggleDrawer);
  }

  handleOnClick({ routeName }: any) {
    this.$router.push({ name: routeName });
  }

  handleToggleDrawer() {
    this.isOpen = !this.isOpen;
  }
}
