
import { Component, Vue, Prop } from "vue-property-decorator";

import axios from "axios";
import { Report } from "@/types";

import Loading from "../shared/Loading.vue";
import DisplayReports from "./DisplayReports.vue";

import { QUERY_GET_REPORTS } from "./graphql";

@Component({ components: { DisplayReports, Loading } })
export default class ReportList extends Vue {
  loading: boolean = false;

  reports: Report[] = [];

  error: boolean = false;

  mounted() {
    this.fetchReports();
  }

  async fetchReports() {
    this.loading = true;

    try {
      const { data } = await this.$apollo.query({ query: QUERY_GET_REPORTS });

      if (!data) {
        throw new Error("Unable to find reports");
      }

      this.reports = data.reports;
      this.loading = false;
    } catch (err) {
      this.$store.commit("setSnackMessageWithColor", {
        message: err,
        color: "error"
      });
    }
  }

  handleBackClick() {
    this.$router.push({ name: "dashboard" });
  }

  async handleRefreshData() {
    await this.fetchReports();
    this.$store.commit("setSnackMessageWithColor", {
      message: "Data has been refreshed",
      color: "success"
    });
  }
}
