import gql from "graphql-tag";

export const QUERY_GET_REPORTS = gql`
  query get_reports {
    reports {
      id
      createdAt
      tipId
      storyId
      userId
      commentId
      reporter {
        id
        username
      }
      reporterId
    }
  }
`;
