import gql from "graphql-tag";

export const QUERY_GET_STORIES = gql`
  query get_stories {
    allStories {
      id
      title
      moderation
      theme {
        id
        title
      }
      createdAt
    }
  }
`;

export const QUERY_COUNT_STORY = gql`
  query count_story {
    nbStory
  }
`;

export const QUERY_GET_STORY_BY_ID = gql`
  query get_story($storyId: String!) {
    story(id: $storyId) {
      id
      title
      moderation
      theme {
        id
        title
      }
      author {
        id
        username
      }
      medias {
        id
        type
        createdAt
        image {
          originalFile
          thumbnailFile
          bigFile
        }
        video {
          m3u8File
        }
      }
      createdAt
    }
  }
`;

export const MUTATION_EDIT_STORY = gql`
  mutation UpdateStory($story: UpdateStoryInput!) {
    updateStory(updateStoryData: $story) {
      id
    }
  }
`;
