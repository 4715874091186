
import Vue from "vue";
import VueFilterDateFormat from "vue-filter-date-format";

Vue.use(VueFilterDateFormat, {
  dayOfWeekNames: [
    "Dimanche",
    "Lundi",
    "Mardi",
    "Mercredi",
    "Jeudi",
    "Vendredi",
    "Samedi"
  ],
  dayOfWeekNamesShort: ["Dim", "Lun", "Mar", "Mer", "Jeu", "Ven", "Sam"],
  monthNames: [
    "Janvier",
    "Février",
    "Mars",
    "Avril",
    "Mai",
    "Juin",
    "Juillet",
    "Aout",
    "Septembre",
    "Octobre",
    "Novembre",
    "Décembre"
  ],
  monthNamesShort: [
    "Jan",
    "Fev",
    "Mar",
    "Avr",
    "Mai",
    "Juin",
    "Juil",
    "Aou",
    "Sep",
    "Oct",
    "Nov",
    "Dec"
  ]
});

export default Vue.extend({
  name: "App",

  components: {},

  data: () => ({
    displaySnackbar: false
  }),
  computed: {
    snackMessageWithColor() {
      const data = this.$store.getters.getSnackMessageWithColor;
      let { message } = data;

      if (
        data.message &&
        data.message.graphQLErrors &&
        data.message.graphQLErrors.length > 0
      ) {
        // eslint-disable-next-line
        message = data.message.graphQLErrors[0].message;
      }

      return {
        color: data.color,
        message
      };
    }
  },
  watch: {
    snackMessageWithColor(params) {
      const { message } = params;
      this.displaySnackbar = message && message.toString().length > 0;
    }
  }
});
