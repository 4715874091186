
import { Component, Vue, Prop } from "vue-property-decorator";

import axios from "axios";
import { User } from "@/types";

import Loading from "../shared/Loading.vue";
import DisplayUsers from "./DisplayUsers.vue";

import { QUERY_GET_USERS } from "./graphql";

@Component({ components: { DisplayUsers, Loading } })
export default class UserList extends Vue {
  loading: boolean = false;

  users: User[] = [];

  error: boolean = false;

  mounted() {
    this.fetchUsers();
  }

  async fetchUsers() {
    this.loading = true;

    try {
      const { data } = await this.$apollo.query({ query: QUERY_GET_USERS });

      if (!data) {
        throw new Error("Unable to find users");
      }

      this.users = data.users;
      this.loading = false;
    } catch (err) {
      this.$store.commit("setSnackMessageWithColor", {
        message: err,
        color: "error"
      });
    }
  }

  handleBackClick() {
    this.$router.push({ name: "dashboard" });
  }

  async handleRefreshData() {
    await this.fetchUsers();
    this.$store.commit("setSnackMessageWithColor", {
      message: "Data has been refreshed",
      color: "success"
    });
  }

  handleOnCreateNewUser() {
    this.$router.push({ name: "create-user" });
  }
}
