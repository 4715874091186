import { ApolloClient } from "apollo-client";
import { InMemoryCache } from "apollo-cache-inmemory";
import { HttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import { createUploadLink } from "apollo-upload-client";

// HTTP connexion to the API
const httpLink = new HttpLink({
  // You should use an absolute URL here
  uri: `${process.env.VUE_APP_ADMIN_URL}/graphql`
});

// Cache implementation
const cache = new InMemoryCache();

/*const uploadLink = createUploadLink({
  uri: `${process.env.VUE_APP_BACKEND_URL}/graphql`
});*/

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("user_token");

  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ""
    }
  };
});

// Create the apollo client
const apolloClient = new ApolloClient({
  link: authLink.concat(httpLink),
  cache,
  defaultOptions: {
    query: { fetchPolicy: "no-cache" },
    watchQuery: { fetchPolicy: "no-cache" }
  }
});

export default apolloClient;
