
import { Component, Vue, Prop } from "vue-property-decorator";

import axios from "axios";
import { Theme } from "@/types";

import Loading from "../shared/Loading.vue";
import DisplayThemes from "./DisplayThemes.vue";

import { QUERY_GET_THEMES } from "./graphql";

@Component({ components: { DisplayThemes, Loading } })
export default class ThemeList extends Vue {
  loading: boolean = false;

  themes: Theme[] = [];

  error: boolean = false;

  mounted() {
    this.fetchThemes();
  }

  async fetchThemes() {
    this.loading = true;

    try {
      const { data } = await this.$apollo.query({
        query: QUERY_GET_THEMES
      });

      if (!data) {
        throw new Error("Unable to find themes");
      }

      this.themes = data.themes;
      this.loading = false;
    } catch (err) {
      this.$store.commit("setSnackMessageWithColor", {
        message: err,
        color: "error"
      });
    }
  }

  handleBackClick() {
    this.$router.push({ name: "dashboard" });
  }

  async handleRefreshData() {
    await this.fetchThemes();
    this.$store.commit("setSnackMessageWithColor", {
      message: "Data has been refreshed",
      color: "success"
    });
  }

  handleOnCreateNewTheme() {
    this.$router.push({ name: "create-theme" });
  }
}
