
import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import Loading from "@/components/shared/Loading.vue";
import EventBus from "@/components/shared/EventBus.vue";
import ConfirmDialog from "@/components/shared/ConfirmDialog.vue";

import { QUERY_GET_USER_BY_ID, MUTATION_EDIT_USER } from "./graphql";

import axios from "axios";
import { User, ModerationEnum } from "@/types";

@Component({ components: { Loading, ConfirmDialog } })
export default class UserForm extends Vue {
  user: User | null = null;
  formRules: any = {
    required: [(v: number) => !!v || "Field is required"],
    requiredNumber: [(v: number) => v >= 0 || "Must be greater than 0"]
  };

  formPassword: string = "";

  formIsValid: boolean = false;

  formIsActive: boolean = false;

  formIsAdmin: boolean = false;

  formIsVerified: boolean = false;

  loading: boolean = false;

  confirmDialogIsOpen: boolean = false;

  previewImageUrl: string | null = null;

  vueAppAdminUrl: string = "";

  get userId(): string {
    const { userId = "" } = this.$route.params;
    return userId;
  }

  get isEditMode(): boolean {
    return this.userId !== "";
  }

  get form(): any {
    return this.$refs.form;
  }

  async mounted() {
    this.loading = true;

    await this.fetchUser();

    this.loading = false;
  }

  async fetchUser() {
    if (!this.isEditMode) return;

    try {
      const { data } = await this.$apollo.query({
        query: QUERY_GET_USER_BY_ID,
        variables: { userId: this.userId }
      });

      // console.log("user/data", JSON.stringify(data));

      if (!data && data.user !== null) {
        throw new Error("Unable to find user");
      }
      this.user = data.user;
      this.formIsActive = data.user.isActive;
      this.formIsAdmin = data.user.isAdmin;
      this.formIsVerified = data.user.isVerified;

      EventBus.$emit("handleAddToBreadcrumb", [
        { label: this.user ? this.user.fullname : "Nouveau" }
      ]);
    } catch (err) {
      this.$store.commit("setSnackMessageWithColor", {
        message: err,
        color: "error"
      });
      this.$router.push({ name: "users" });
    }
  }

  handleBackClick() {
    this.$router.push({ name: "users" });
  }

  async handleOnUpdate(close: boolean) {
    if (!this.form.validate()) return;
    this.loading = true;

    try {
      const variables = {
        user: {
          id: this.userId,
          isActive: this.formIsActive,
          isAdmin: this.formIsAdmin,
          isVerified: this.formIsVerified,
          password: this.formPassword ? this.formPassword : undefined
        }
      };

      const mutation = MUTATION_EDIT_USER;
      const { data } = await this.$apollo.mutate({ mutation, variables });

      this.$store.commit("setSnackMessageWithColor", {
        message: "User informations was saved successfully",
        color: "success"
      });

      this.formPassword = "";

      if (close) {
        this.$router.push({ name: "users" });
        return;
      }
    } catch (err) {
      this.$store.commit("setSnackMessageWithColor", {
        message: err,
        color: "error"
      });
    }

    this.loading = false;
  }

  isDeleted() {
    return this.user ? this.user.moderation === ModerationEnum.DELETED : false;
  }

  isBlocked() {
    return this.user ? this.user.moderation === ModerationEnum.BLOQUED : false;
  }

  isPending() {
    return this.user ? this.user.moderation === ModerationEnum.PENDING : false;
  }

  isValid() {
    return this.user ? this.user.moderation === ModerationEnum.VALID : false;
  }

  async handleOnDelete() {
    this.loading = true;

    const res = await this.$confirm(
      "Voulez-vous rééelement supprimé l'utilisateur ?",
      { title: "Warning" }
    );

    if (res) {
      try {
        const { data } = await this.$apollo.mutate({
          mutation: MUTATION_EDIT_USER,
          variables: {
            user: { id: this.userId, moderation: ModerationEnum.DELETED }
          }
        });

        this.$store.commit("setSnackMessageWithColor", {
          message: "User was deleted",
          color: "success"
        });

        this.$router.push({ name: "users" });
      } catch (err) {
        this.$store.commit("setSnackMessageWithColor", {
          message: err,
          color: "error"
        });
      }
    }

    this.loading = false;
  }

  async handleOnBlock() {
    this.loading = true;
    try {
      const { data } = await this.$apollo.mutate({
        mutation: MUTATION_EDIT_USER,
        variables: {
          user: { id: this.userId, moderation: ModerationEnum.BLOQUED }
        }
      });

      this.$store.commit("setSnackMessageWithColor", {
        message: "User was bloqued",
        color: "success"
      });

      this.fetchUser();
    } catch (err) {
      this.$store.commit("setSnackMessageWithColor", {
        message: err,
        color: "error"
      });
    }

    this.loading = false;
  }

  async handleOnCancel() {
    this.confirmDialogIsOpen = false;
  }

  async handleConfirmOnDelete() {
    this.confirmDialogIsOpen = false;
  }

  async handleOnValid() {
    this.loading = true;

    try {
      const { data } = await this.$apollo.mutate({
        mutation: MUTATION_EDIT_USER,
        variables: {
          user: { id: this.userId, moderation: ModerationEnum.VALID }
        }
      });

      this.$store.commit("setSnackMessageWithColor", {
        message: "User was validated",
        color: "success"
      });

      this.fetchUser();
    } catch (err) {
      this.$store.commit("setSnackMessageWithColor", {
        message: err,
        color: "error"
      });
    }

    this.loading = false;
  }
}
