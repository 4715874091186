
import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import { CityCategory } from "@/types";

@Component
export default class DisplayCitiesCategories extends Vue {
  @Prop() cities_categories!: CityCategory[];

  @Prop() loading!: boolean;

  isLoading: boolean = false;

  selected: any = [];

  pagination: any = {
    sortBy: "city.id"
  };

  headers: any = [
    { text: "Ville", value: "city.city" },
    { text: "Url", value: "url" },
    { text: "Catégorie", value: "category" },
    { text: "Title", value: "title" },
    { text: "Content", value: "content" },
    { text: "Actions", value: "action", sortable: false }
  ];

  search: String = "";

  @Watch("loading", { immediate: true })
  handleLoadingChange(value: boolean) {
    this.isLoading = value;
  }

  toggleAll() {
    if (this.selected.length) this.selected = [];
    else this.selected = this.cities_categories.slice();
  }

  changeSort(column: string) {
    if (this.pagination.sortBy === column) {
      this.pagination.descending = !this.pagination.descending;
    } else {
      this.pagination.sortBy = column;
      this.pagination.descending = false;
    }
  }

  filteredCitiesCategories() {
    return this.cities_categories.filter((cc: CityCategory) => {
      let search = this.search.toLowerCase();
      search = search.replace(/^.*localhost:[0-9]+/g, "");
      search = search.replace(/^.*.vercel.app/g, "");
      search = search.replace(/^.*.tipshout.com/g, "");
      // console.log("Search : ", search);

      return cc.simple_url && cc.simple_url.toLowerCase().includes(search);
    });
  }
}
