
import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import Loading from "@/components/shared/Loading.vue";
import EventBus from "@/components/shared/EventBus.vue";
import ConfirmDialog from "@/components/shared/ConfirmDialog.vue";

import {
  QUERY_GET_CATEGORY_BY_ID,
  QUERY_GET_TOP_CATEGORIES,
  MUTATION_ADD_CATEGORY,
  MUTATION_EDIT_CATEGORY
} from "./graphql";

import axios from "axios";
import { Category } from "@/types";

@Component({ components: { Loading } })
export default class CategoryForm extends Vue {
  category: Category | null = null;

  topCategories: Category[] = [];

  formRules: any = {
    required: [(v: number) => !!v || "Field is required"],
    requiredNumber: [(v: number) => v >= 0 || "Must be greater than 0"]
  };

  formIsValid: boolean = false;

  formTitle: string = "";
  formDescription: string = "";
  formColor: string = "";
  formIcon: string = "";
  formOrder: number = 0;
  formIsLocation: boolean = false;
  formParentCategoryId: string = "";

  loading: boolean = false;

  confirmDialogIsOpen: boolean = false;

  previewImageUrl: string | null = null;

  vueAppAdminUrl: string = "";

  get categoryId(): string {
    const { categoryId = "" } = this.$route.params;
    return categoryId;
  }

  get isEditMode(): boolean {
    return this.categoryId !== "";
  }

  get form(): any {
    return this.$refs.form;
  }

  async mounted() {
    this.loading = true;

    await this.fetchTopCategories();
    await this.fetchCategory();

    this.loading = false;
  }

  async fetchTopCategories() {
    try {
      const { data } = await this.$apollo.query({
        query: QUERY_GET_TOP_CATEGORIES
      });

      this.topCategories = data.topLevelCategories.filter(
        (cat: Category) => cat.id !== this.categoryId
      );
    } catch (err) {
      this.$store.commit("setSnackMessageWithColor", {
        message: err,
        color: "error"
      });
      this.$router.push({ name: "categories" });
    }
  }

  async fetchCategory() {
    if (!this.isEditMode) return;

    try {
      const { data } = await this.$apollo.query({
        query: QUERY_GET_CATEGORY_BY_ID,
        variables: { categoryId: this.categoryId }
      });

      if (!data && data.category !== null) {
        throw new Error("Unable to find category");
      }
      this.category = data.category;
      this.formTitle = data.category.title || "";
      this.formDescription = data.category.description || "";
      this.formColor = data.category.color || "";
      this.formIcon = data.category.icon || "";
      this.formOrder = data.category.order || 0;
      this.formIsLocation = data.category.isLocation || 0;
      this.formParentCategoryId = data.category.parentCategory
        ? data.category.parentCategory.id
        : "";

      EventBus.$emit("handleAddToBreadcrumb", [{ label: this.formTitle }]);
    } catch (err) {
      this.$store.commit("setSnackMessageWithColor", {
        message: err,
        color: "error"
      });
      this.$router.push({ name: "categories" });
    }
  }

  handleBackClick() {
    this.$router.push({ name: "categories" });
  }

  async handleOnUpdate(close: boolean) {
    if (!this.form.validate()) return;
    this.loading = true;

    try {
      const variables = {
        category: {
          id: this.isEditMode ? this.categoryId : undefined,
          title: this.formTitle,
          color: this.formColor,
          icon: this.formIcon,
          description: this.formDescription,
          isLocation: !!this.formIsLocation,
          order: +this.formOrder,
          parentCategoryId: this.formParentCategoryId
        }
      };

      const mutation =
        this.category && this.categoryId
          ? MUTATION_EDIT_CATEGORY
          : MUTATION_ADD_CATEGORY;
      const { data } = await this.$apollo.mutate({ mutation, variables });

      this.$store.commit("setSnackMessageWithColor", {
        message: "Category informations was saved successfully",
        color: "success"
      });

      if (close) {
        this.$router.push({ name: "categories" });
        return;
      }
    } catch (err) {
      this.$store.commit("setSnackMessageWithColor", {
        message: err,
        color: "error"
      });
    }

    this.loading = false;
  }
}
