
import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import Loading from "@/components/shared/Loading.vue";
import EventBus from "@/components/shared/EventBus.vue";
import ConfirmDialog from "@/components/shared/ConfirmDialog.vue";

import {
  MUTATION_EDIT_CITY_CATEGORY,
  QUERY_GET_FULL_CITY_CATEGORY_BY_ID
} from "./graphql";

import { CityCategory } from "@/types";

@Component({ components: { Loading } })
export default class CityCategoryForm extends Vue {
  city_category: CityCategory | null = null;

  formRules: any = {
    required: [(v: number) => !!v || "Field is required"],
    requiredNumber: [(v: number) => v >= 0 || "Must be greater than 0"]
  };

  formIsValid: boolean = false;

  formTitle: string = "";
  formContent: string = "";

  loading: boolean = false;

  confirmDialogIsOpen: boolean = false;

  vueAppAdminUrl: string = "";

  get cityId(): number {
    // console.log("params", this.$route.params);
    // const { params = "" } = this.$route.params;
    // const t_params = params.split("-");
    return parseInt(this.$route.params["cityId"]);
  }

  get categoryId(): string {
    // const { params = "" } = this.$route.params;
    // const t_params = params.split("-");
    return this.$route.params["categoryId"];
  }

  get form(): any {
    return this.$refs.form;
  }

  async mounted() {
    this.loading = true;

    await this.fetchCityCategory();

    this.loading = false;
  }

  async fetchCityCategory() {
    try {
      const { data } = await this.$apollo.query({
        query: QUERY_GET_FULL_CITY_CATEGORY_BY_ID,
        variables: {
          cityId: this.cityId,
          categoryId: this.categoryId,
          language: "fr"
        }
      });

      // console.log("data", data);

      if (!data && data.fullCityCategory !== null) {
        throw new Error("Unable to find category");
      }
      this.formTitle = data.fullCityCategory.title || "";
      this.formContent = data.fullCityCategory.content || "";
      this.city_category = data.fullCityCategory;

      EventBus.$emit("handleAddToBreadcrumb", [{ label: this.formTitle }]);
    } catch (err) {
      this.$store.commit("setSnackMessageWithColor", {
        message: err,
        color: "error"
      });
      this.$router.push({ name: "articles" });
    }
  }

  handleBackClick() {
    this.$router.push({ name: "articles" });
  }

  async handleOnUpdate(close: boolean) {
    if (!this.form.validate()) return;
    this.loading = true;

    try {
      const variables = {
        city_category: {
          cityId: this.cityId,
          categoryId: this.categoryId,
          title: this.formTitle,
          content: this.formContent,
          language: "fr"
        }
      };

      const mutation = MUTATION_EDIT_CITY_CATEGORY;
      const { data } = await this.$apollo.mutate({ mutation, variables });

      this.$store.commit("setSnackMessageWithColor", {
        message: "Article informations was saved successfully",
        color: "success"
      });

      if (close) {
        this.$router.push({ name: "articles" });
        return;
      }
    } catch (err) {
      this.$store.commit("setSnackMessageWithColor", {
        message: err,
        color: "error"
      });
    }

    this.loading = false;
  }
}
