
import { Component, Vue, Prop } from "vue-property-decorator";

import { QUERY_COUNT_TIP } from "./Tip/graphql";
import { QUERY_COUNT_USER } from "./User/graphql";
import { QUERY_COUNT_STORY } from "./Story/graphql";

import axios from "axios";
import { Tip } from "@/types";
import { Story } from "@/types";

@Component
export default class Dashboard extends Vue {
  loading: boolean = false;

  nbUser: number = 0;
  nbTip: number = 0;
  nbStory: number = 0;

  mounted() {
    this.fetchStat();
  }

  async fetchStat() {
    this.loading = true;

    try {
      const nbTipQuery = await this.$apollo.query({
        query: QUERY_COUNT_TIP
      });

      if (nbTipQuery.data) this.nbTip = nbTipQuery.data.nbTip;

      const nbStoryQuery = await this.$apollo.query({
        query: QUERY_COUNT_STORY
      });

      if (nbStoryQuery.data) this.nbStory = nbStoryQuery.data.nbStory;

      const nbUserQuery = await this.$apollo.query({
        query: QUERY_COUNT_USER
      });

      if (nbUserQuery.data) this.nbUser = nbUserQuery.data.nbUser;

      this.loading = false;
    } catch (err) {
      this.$store.commit("setSnackMessageWithColor", {
        message: err,
        color: "error"
      });
    }
  }
}
