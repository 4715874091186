import gql from "graphql-tag";

export const QUERY_GET_TIPS = gql`
  query get_tips {
    tips(searchTipData: { meIncluded: true }, limit: 10000) {
      id
      title
      description
      moderation
      category {
        id
        title
      }
      createdAt
    }
  }
`;

export const QUERY_COUNT_TIP = gql`
  query count_tip {
    nbTip
  }
`;

export const QUERY_GET_TIP_BY_ID = gql`
  query get_tip($tipId: String!) {
    tip(id: $tipId) {
      id
      title
      description
      moderation
      category {
        id
        title
      }
      author {
        id
        fullname
      }
      medias {
        id
        type
        createdAt
        image {
          originalFile
          thumbnailFile
          bigFile
        }
        video {
          m3u8File
        }
      }
      #  comments {
      #    id
      #    text
      #    createdAt
      #    author {
      #      id
      #      fullname
      #    }
      #  }
      createdAt
    }
  }
`;

export const MUTATION_EDIT_TIP = gql`
  mutation UpdateTip($tip: UpdateTipInput!) {
    updateTip(updateTipData: $tip) {
      id
    }
  }
`;
