
import { Component, Vue, Prop, Watch } from "vue-property-decorator";

import Loading from "@/components/shared/Loading.vue";
import EventBus from "@/components/shared/EventBus.vue";
import ConfirmDialog from "@/components/shared/ConfirmDialog.vue";

import { QUERY_GET_TIP_BY_ID, MUTATION_EDIT_TIP } from "./graphql";

import axios from "axios";
import { Tip, ModerationEnum } from "@/types";

@Component({ components: { Loading, ConfirmDialog } })
export default class TipForm extends Vue {
  tip: Tip | null = null;
  formRules: any = {
    required: [(v: number) => !!v || "Field is required"],
    requiredNumber: [(v: number) => v >= 0 || "Must be greater than 0"]
  };

  formIsValid: boolean = false;

  formTitle: string = "";

  loading: boolean = false;

  confirmDialogIsOpen: boolean = false;

  previewImageUrl: string | null = null;

  vueAppAdminUrl: string = "";

  statusDict = { pending: "en cours", refused: "refusé", processed: "traité" };

  get tipId(): string {
    const { tipId = "" } = this.$route.params;
    return tipId;
  }

  get isEditMode(): boolean {
    return this.tipId !== "";
  }

  get form(): any {
    return this.$refs.form;
  }

  async mounted() {
    this.loading = true;

    this.vueAppAdminUrl = process.env.VUE_APP_ADMIN_URL + "";

    await this.fetchTip();

    this.loading = false;
  }

  async fetchTip() {
    if (!this.isEditMode) return;

    try {
      const { data } = await this.$apollo.query({
        query: QUERY_GET_TIP_BY_ID,
        variables: { tipId: this.tipId }
      });

      if (!data && data.tip !== null) {
        throw new Error("Unable to find tip");
      }
      this.tip = data.tip;
      this.formTitle = data.tip.title || "";

      EventBus.$emit("handleAddToBreadcrumb", [{ label: this.formTitle }]);
    } catch (err) {
      this.$store.commit("setSnackMessageWithColor", {
        message: err,
        color: "error"
      });
      this.$router.push({ name: "tips" });
    }
  }

  handleBackClick() {
    this.$router.push({ name: "tips" });
  }

  async handleOnUpdate(close: boolean) {
    if (!this.form.validate()) return;
    this.loading = true;

    try {
      this.$store.commit("setSnackMessageWithColor", {
        message: "Tip informations was saved successfully",
        color: "success"
      });

      if (close) {
        this.$router.push({ name: "tips" });
        return;
      }
    } catch (err) {
      this.$store.commit("setSnackMessageWithColor", {
        message: err,
        color: "error"
      });
    }

    this.loading = false;
  }

  isDeleted() {
    return this.tip ? this.tip.moderation === ModerationEnum.DELETED : false;
  }

  isBlocked() {
    return this.tip ? this.tip.moderation === ModerationEnum.BLOQUED : false;
  }

  isPending() {
    return this.tip ? this.tip.moderation === ModerationEnum.PENDING : false;
  }

  isValid() {
    return this.tip ? this.tip.moderation === ModerationEnum.VALID : false;
  }

  async handleOnDelete() {
    this.loading = true;

    const res = await this.$confirm(
      "Voulez-vous rééelement supprimé la recommandation ?",
      { title: "Warning" }
    );

    if (res) {
      try {
        const { data } = await this.$apollo.mutate({
          mutation: MUTATION_EDIT_TIP,
          variables: {
            tip: { id: this.tipId, moderation: ModerationEnum.DELETED }
          }
        });

        this.$store.commit("setSnackMessageWithColor", {
          message: "Tip was deleted",
          color: "success"
        });

        this.$router.push({ name: "tips" });
      } catch (err) {
        this.$store.commit("setSnackMessageWithColor", {
          message: err,
          color: "error"
        });
      }
    }

    this.loading = false;
  }

  async handleOnBlock() {
    this.loading = true;
    try {
      const { data } = await this.$apollo.mutate({
        mutation: MUTATION_EDIT_TIP,
        variables: {
          tip: { id: this.tipId, moderation: ModerationEnum.BLOQUED }
        }
      });

      this.$store.commit("setSnackMessageWithColor", {
        message: "Tip was bloqued",
        color: "success"
      });

      this.fetchTip();
    } catch (err) {
      this.$store.commit("setSnackMessageWithColor", {
        message: err,
        color: "error"
      });
    }

    this.loading = false;
  }

  async handleOnValid() {
    this.loading = true;

    try {
      const { data } = await this.$apollo.mutate({
        mutation: MUTATION_EDIT_TIP,
        variables: {
          tip: { id: this.tipId, moderation: ModerationEnum.VALID }
        }
      });

      this.$store.commit("setSnackMessageWithColor", {
        message: "Tip was validated",
        color: "success"
      });

      this.fetchTip();
    } catch (err) {
      this.$store.commit("setSnackMessageWithColor", {
        message: err,
        color: "error"
      });
    }

    this.loading = false;
  }
}
